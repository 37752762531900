import React from 'react';
import { Hero } from 'carbonate-ui';
import AmpLayout from '../components/AmpLayout';

import config from '../config.js';
import products from '../collections/products.json';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import chevronDown from '@fortawesome/fontawesome-free-solid/faChevronDown';

export default (props) => {
  const dpsg = 'https://www.kdpproductfacts.com';
  
  return (
      <AmpLayout
        favicon={config.site.head.favicon}
        metaTitle={config.site.head.metaTitle}
        metaDescription={config.site.head.metaDescription}
      >
        <div className="amp-hero">
          <amp-img class="hidden-mobile" src={config.site.heroImage} width="2400" height="1400" layout="responsive" />
          <amp-img class="visible-mobile" src={config.site.heroImageMobile} width="1150" height="1600" layout="responsive" />
          <a className="hint" href="#blurb">
            <FontAwesomeIcon className="hint__icon" icon={chevronDown} />
          </a>
        </div>
        <section id="blurb" className="blurb section">
          <div className="container">
            <h1 className="blurb__title">{config.site.blurb.title}</h1>
            <p className="blurb__text">{config.site.blurb.text}</p>
          </div>
        </section>
        <section className="products section">
          <div className="container">
            <h3 className="products__title">Products</h3>
            <div className="products__container">
              {products.map(product => (
                <div
                  className="product"
                  key={`product-${product.slug}`}
                >
                  <a
                    className="product__link"
                    href={`${dpsg}/en/product/${product.data.misc['Product Code']}`}
                    target="_blank"
                  >
                    <amp-img
                      width="175"
                      height="350"
                      layout="responsive"
                      class="product__img"
                      src={`${dpsg}/smedia/kdpproductfacts.com/www/product_images/medium/${product.data.misc['Product Code']}.png`}
                      alt={product.data.misc['Product Name']}
                    />
                    <span className="product__name">{product.data.misc['Product Name']}</span>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </section>
      </AmpLayout>
    );
  };
