import '../scss/style.scss';
import React from 'react';
import Helmet from 'react-helmet';
import config from '../config.js';

export default (props) => {
  return (
    <div className="amp">

      <Helmet>
        <title>{`${props.metaTitle}`}</title>
        <meta name="title" content={props.metaTitle} />
        <meta name="description" content={props.metaDescription} />

        <link rel="shortcut icon" href={props.favicon} />

        <script async src="https://cdn.ampproject.org/v0.js"></script>
      </Helmet>

      <div className="header">
        <a href="/amp">
          <amp-img
            src={config.site.logo}
            width="48"
            height="40"
          />
        </a>
      </div>

      {props.children}

      <div className="footer">
        <a href="/amp" className="footer__logo-link">
          <amp-img
            class="footer__logo"
            src={config.site.logo}
            width="180"
            height="150"
            alt={config.site.logoAltText}
          />
        </a>
        <ul className="footer__list">
          <li className="footer__list-item"><a className="footer__link" href="https://www.drpeppersnapplegroup.com/privacy" target="_blank">Privacy Policy</a></li>
          <li className="footer__list-item"><a className="footer__link" href="https://www.drpeppersnapplegroup.com/terms" target="_blank">Terms of Use</a></li>
          <li className="footer__list-item"><a className="footer__link" href="https://www.drpeppersnapplegroup.com/accessibility" target="_blank">Accessibility Statement</a></li>
          <li className="footer__list-item"><a className="footer__link" href="https://www.keurigdrpepper.com/en/careers/overview" target="_blank">Careers</a></li>
          <li className="footer__list-item"><a className="footer__link" href="https://www.letsplay.com/" target="_blank">Let's Play</a></li>
          <li className="footer__list-item"><a className="footer__link" href="https://www.drpeppersnapplegroup.com/contact" target="_blank">Contact</a></li>
        </ul>
        <p className="footer__text">{config.site.legal}</p>
      </div>
    </div>
    );
  };
